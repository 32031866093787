define("ember-sortable/services/ember-sortable", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class EmberSortableService extends _service.default {
    constructor() {
      super(...arguments);
      /**
       * Internal State for any groups currently in DOM
       *
       * {
       *   groupName: {
       *     groupModifier: object,
       *     items: []
       *   }
       * }
       * @type {{}}
       */
      _defineProperty(this, "groups", {});
    }
    /**
     * Register a new group with the service
     *
     * @param {String} groupName
     * @param {SortableGroupModifier} groupModifier
     */
    registerGroup(groupName, groupModifier) {
      if (this.groups[groupName] === undefined) {
        this.groups[groupName] = {
          groupModifier: groupModifier,
          items: []
        };
      } else {
        this.groups[groupName].groupModifier = groupModifier;
      }
    }

    /**
     * De-register a group with the service
     *
     * @param {String} groupName
     */
    deregisterGroup(groupName) {
      delete this.groups[groupName];
    }

    /**
     * Register an item with this group
     *
     * @method registerItem
     * @param {String} groupName
     * @param {SortableItemModifier} item
     */
    registerItem(groupName, item) {
      let groupDef = this.fetchGroup(groupName);
      let items = groupDef.items;
      if (items.indexOf(item) === -1) {
        items = [...items, item];
      }
      groupDef.items = items;
    }

    /**
     De-register an item with this group.
      @method deregisterItem
     @param groupName
     @param item
     */
    deregisterItem(groupName, item) {
      let groupDef = this.fetchGroup(groupName);
      let items = groupDef.items;
      const index = items.indexOf(item);
      if (index !== -1) {
        let newItems = [...items.slice(0, index), ...items.slice(index + 1)];
        groupDef.items = newItems;
      }
    }

    /**
     * Fetch a group definition
     *
     * @param {String} groupName
     * @returns {*}
     */
    fetchGroup(groupName) {
      if (this.groups[groupName] === undefined) {
        this.registerGroup(groupName, undefined);
      }
      return this.groups[groupName];
    }
  }
  _exports.default = EmberSortableService;
});