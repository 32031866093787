define("@ukgepic/hrsd-ember-ignite/modifiers/auto-present-dismiss", ["exports", "ember-modifier", "@ember/destroyable"], function (_exports, _emberModifier, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function removeElement(instance) {
    instance.element?.dismiss();
  }
  class DialogPresent extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      _defineProperty(this, "element", null);
      (0, _destroyable.registerDestructor)(this, removeElement);
    }
    modify(element) {
      // Save off the element the first time for convenience with #moveElement
      if (!this.element) {
        this.element = element;
        this.element.present();
      }
    }
  }
  _exports.default = DialogPresent;
});