define("@ukgepic/hrsd-ember-ignite/components/ignite-task-drawer", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let
    (hash
      openDrawer=(fn (mut this.drawerIsOpen) true)
      drawerIsOpen=this.drawerIsOpen
    )
    as |api|
  }}
    <ukg-task-drawer-container ...attributes>
      <ukg-task-drawer
        is-open={{this.drawerIsOpen}}
        visual-state="overlay"
        has-reset-button={{this.hasResetButton}}
        has-apply-button={{this.hasApplyButton}}
        {{on "ukgTaskDrawerResetButtonClicked" this.resetAction}}
        {{on "ukgTaskDrawerApplyButtonClicked" this.applyAction}}
        {{on "ukgTaskDrawerWillClose" (fn (mut this.drawerIsOpen) false)}}
      >
        {{yield
          (hash
            drawer=(component "ignite-task-drawer/-drawer-content")
            api=api
          )
        }}
      </ukg-task-drawer>
  
      {{yield
        (hash
          content=(component "ignite-task-drawer/-main-content")
          api=api
        )
      }}
    </ukg-task-drawer-container>
  {{/let}}
  */
  {
    "id": "lGMkORiR",
    "block": "[[[44,[[28,[37,1],null,[[\"openDrawer\",\"drawerIsOpen\"],[[28,[37,2],[[28,[37,3],[[30,0,[\"drawerIsOpen\"]]],null],true],null],[30,0,[\"drawerIsOpen\"]]]]]],[[[1,\"  \"],[11,\"ukg-task-drawer-container\"],[17,2],[12],[1,\"\\n    \"],[11,\"ukg-task-drawer\"],[16,\"is-open\",[30,0,[\"drawerIsOpen\"]]],[24,\"visual-state\",\"overlay\"],[16,\"has-reset-button\",[30,0,[\"hasResetButton\"]]],[16,\"has-apply-button\",[30,0,[\"hasApplyButton\"]]],[4,[38,4],[\"ukgTaskDrawerResetButtonClicked\",[30,0,[\"resetAction\"]]],null],[4,[38,4],[\"ukgTaskDrawerApplyButtonClicked\",[30,0,[\"applyAction\"]]],null],[4,[38,4],[\"ukgTaskDrawerWillClose\",[28,[37,2],[[28,[37,3],[[30,0,[\"drawerIsOpen\"]]],null],false],null]],null],[12],[1,\"\\n      \"],[18,3,[[28,[37,1],null,[[\"drawer\",\"api\"],[[50,\"ignite-task-drawer/-drawer-content\",0,null,null],[30,1]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,3,[[28,[37,1],null,[[\"content\",\"api\"],[[50,\"ignite-task-drawer/-main-content\",0,null,null],[30,1]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]],[\"api\",\"&attrs\",\"&default\"],false,[\"let\",\"hash\",\"fn\",\"mut\",\"on\",\"yield\",\"component\"]]",
    "moduleName": "@ukgepic/hrsd-ember-ignite/components/ignite-task-drawer.hbs",
    "isStrictMode": false
  });
  let IgniteTaskDrawerComponent = _exports.default = (_class = class IgniteTaskDrawerComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "drawerIsOpen", _descriptor, this);
    }
    get hasResetButton() {
      return this.args.hasResetButton ?? true;
    }
    get hasApplyButton() {
      return this.args.hasApplyButton ?? true;
    }
    applyAction() {
      this.drawerIsOpen = false;
      this.args.onApplyClicked();
    }
    resetAction() {
      this.drawerIsOpen = false;
      this.args.onResetClicked();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "drawerIsOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "applyAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "applyAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetAction"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IgniteTaskDrawerComponent);
});