define("@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/helpers/patch-article-images", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class PatchArticleUrlsHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "withCustomParams", false);
    }
    get host() {
      throw new Error('You should override host attribute on PatchArticleUrlsHelper');
    }
    addToken() {
      throw new Error('You should override addToken method on PatchArticleUrlsHelper');
    }
    compute(_ref) {
      let [body] = _ref;
      if (!body) {
        return;
      }
      return body.replace(/\bsrc="([^"]+)"/g, (_, value) => {
        if (value.startsWith(this.host)) {
          return `src="${this.addToken(value)}"`;
        } else {
          return `src="${value}"`;
        }
      });
    }
  }
  _exports.default = PatchArticleUrlsHelper;
});