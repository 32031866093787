define("@ukgepic/hrsd-ember-ignite/components/ignite-select-multiple", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ukg-select
    ...attributes
    multiple
    {{did-insert this.updateSelectValue}}
    {{did-update this.updateSelectValue @value}}
  >
    <div data-test-select-options-wrapper>
      {{yield}}
    </div>
  </ukg-select>
  */
  {
    "id": "tNKL3stW",
    "block": "[[[11,\"ukg-select\"],[17,1],[24,\"multiple\",\"\"],[4,[38,0],[[30,0,[\"updateSelectValue\"]]],null],[4,[38,1],[[30,0,[\"updateSelectValue\"]],[30,2]],null],[12],[1,\"\\n  \"],[10,0],[14,\"data-test-select-options-wrapper\",\"\"],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@value\",\"&default\"],false,[\"did-insert\",\"did-update\",\"yield\"]]",
    "moduleName": "@ukgepic/hrsd-ember-ignite/components/ignite-select-multiple.hbs",
    "isStrictMode": false
  });
  let IgniteSelectMultipleComponent = _exports.default = (_class = class IgniteSelectMultipleComponent extends _component2.default {
    updateSelectValue(igniteSelect, _ref) {
      let [value] = _ref;
      igniteSelect.update(value ?? this.args.value ?? []);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "updateSelectValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelectValue"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IgniteSelectMultipleComponent);
});