define("ember-svg-jar/inlined/unmerge_cells", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9 4v5.001L11 9V4a2 2 0 00-2-2H2v2h7zM9 20v-5h2v5a2 2 0 01-2 2H2v-2h7zM6.121 8.464l-2.828 2.829a1.006 1.006 0 00-.08.09l.08-.09a1.008 1.008 0 00-.292.674L3 12v.033a.997.997 0 00.293.722l2.828 2.829a1 1 0 001.415-1.414L6.366 13H10a1 1 0 000-2H6.415l1.12-1.121a1 1 0 00-1.414-1.415zM15 4v5.001L13 9V4a2 2 0 012-2h7v2h-7zM15 20v-5h-2v5a2 2 0 002 2h7v-2h-7zM17.879 8.464l2.828 2.829c.029.028.055.058.08.09l-.08-.09a1.008 1.008 0 01.292.674L21 12v.033a.997.997 0 01-.293.722l-2.828 2.829a1 1 0 01-1.415-1.414l1.17-1.17H14a1 1 0 010-2h3.585l-1.12-1.121a1 1 0 111.414-1.415z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});