define("@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/helpers/find-article-version", ["exports", "@ember/component/helper", "@ember/service", "@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/models/article-base"], function (_exports, _helper, _service, _articleBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FindArticleVersionHelper = _exports.default = (_class = class FindArticleVersionHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "localizedString", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "locales", _descriptor3, this);
      _defineProperty(this, "removeLocaleObserver", undefined);
      this.removeLocaleObserver = this.intl.onLocaleChanged(this.recompute, this);
    }
    compute(_ref) {
      let [article, locale = undefined] = _ref;
      let localeFound = false;
      let version = undefined;

      /* istanbul ignore if */
      if (!article || !(article instanceof _articleBase.default)) {
        throw new Error('You should pass an instance of an article model to the `find-article-version` helper');
      }
      let {
        locales: localesService
      } = this;
      let locales = locale ? [locale] : this.localizedString.fallbacks(article);
      for (let i = 0; i < locales.length && !version; i++) {
        version = article.versionsByLanguage[locales[i]]?.slice().at(-1);
        // return localeFound to true if version found is in current locale
        // or match locale internal fallback (eg. 'fr-fr' for 'fr-ca' locale)
        localeFound = version && (locale || version.language_code === localesService.locale || version.language_code === localesService.localesList[localesService.locale]?.fallback);
      }
      if (!version && !locale) {
        version = article.versions.slice().at(-1);
      }
      return {
        version,
        localeFound
      };
    }
    willDestroy() {
      this.removeLocaleObserver?.();
      super.willDestroy(...arguments);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "localizedString", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "locales", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});