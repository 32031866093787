define("ember-intl/-private/formatters/format-message", ["exports", "ember", "@ember/template", "ember-intl/-private/formatters/-base"], function (_exports, _ember, _template, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
  const {
    Handlebars: {
      // @ts-expect-error Upstream types are incomplete.
      Utils: {
        escapeExpression
      }
    }
  } = _ember.default;
  function escapeOptions(object) {
    if (typeof object !== 'object') {
      return;
    }
    const escapedOpts = {};
    Object.keys(object).forEach(key => {
      const val = object[key];
      if ((0, _template.isHTMLSafe)(val)) {
        // If the option is an instance of Ember SafeString,
        // we don't want to pass it into the formatter, since the
        // formatter won't know what to do with it. Instead, we cast
        // the SafeString to a regular string using `toHTML`.
        // Since it was already marked as safe we should *not* escape it.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        escapedOpts[key] = val.toHTML();
      } else if (typeof val === 'string') {
        escapedOpts[key] = escapeExpression(val);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        escapedOpts[key] = val; // copy as-is
      }
    });
    return escapedOpts;
  }
  /**
   * @private
   * @hide
   */
  class FormatMessage extends _base.default {
    // ! Function overloads are not passed through generic types for reasons that
    // evade my knowledge. ¯\_(ツ)_/¯
    // For this reason these types need to be manually copied over to the
    // `IntlService#formatMessage`.

    format(intl, stringOrDesc, options) {
      const isHTMLSafe = options && options.htmlSafe;
      // Empty string is considered an err in ember-intl
      // if (typeof stringOrDesc === 'string' && !stringOrDesc) {
      //   return stringOrDesc;
      // }
      const escapedOptions = isHTMLSafe ? escapeOptions(options) : options;
      const desc = stringOrDesc && typeof stringOrDesc === 'object' ? stringOrDesc : {
        id: stringOrDesc,
        defaultMessage: stringOrDesc
      };
      const result = intl.formatMessage(desc, escapedOptions, {
        ignoreTag: true
      });
      return isHTMLSafe ? (0, _template.htmlSafe)(result) : result;
    }
  }
  _exports.default = FormatMessage;
  _defineProperty(FormatMessage, "type", 'message');
});