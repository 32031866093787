define("@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/modifiers/kb-article-container", ["exports", "ember-modifier", "@ember/destroyable", "@ember/service", "@ember/utils"], function (_exports, _emberModifier, _destroyable, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let KBArticleContainerModifier = _exports.default = (_class = class KBArticleContainerModifier extends _emberModifier.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "blobDownload", _descriptor, this);
      _initializerDefineProperty(this, "errors", _descriptor2, this);
      _defineProperty(this, "element", undefined);
      _defineProperty(this, "event", undefined);
      _defineProperty(this, "handler", undefined);
      _defineProperty(this, "_host", void 0);
      _defineProperty(this, "_downloadErrorMessagePrefix", undefined);
      _defineProperty(this, "_downloadingPlaceholder", 'Downloading ...');
      _defineProperty(this, "_downloadingClassLink", undefined);
      _defineProperty(this, "addEventListener", (element, event, handler) => {
        this.element = element;
        this.event = event;
        this.handler = handler;
        element.addEventListener(event, handler);
      });
    }
    removeEventListener(instance) {
      let {
        element,
        event,
        handler
      } = instance;

      /* istanbul ignore else */
      if (element && event && handler) {
        element.removeEventListener(event, handler);
        instance.element = undefined;
        instance.event = undefined;
        instance.handler = undefined;
      }
    }
    modify(element, _, _ref) {
      let {
        host,
        downloadErrorMessagePrefix,
        downloadingPlaceholder,
        downloadingClassLink
      } = _ref;
      if (!host) {
        throw 'You must pass "host" argument to the kb-article-container modifier.';
      } else {
        this._host = host;
      }
      if (downloadErrorMessagePrefix) {
        this._downloadErrorMessagePrefix = downloadErrorMessagePrefix;
      }
      if (downloadingPlaceholder) {
        this._downloadingPlaceholder = downloadingPlaceholder;
      }
      if (downloadingClassLink) {
        this._downloadingClassLink = downloadingClassLink;
      }
      this.addEventListener(element, 'click', this.handleAuthenticatedLinkClick.bind(this));
      (0, _destroyable.registerDestructor)(this, this.removeEventListener);
    }
    async handleAuthenticatedLinkClick(e) {
      if (!e.ctrlKey && !e.metaKey) {
        // event target can be a child of the link
        let link = e.target.closest('[href]');
        if ((0, _utils.isNone)(link)) {
          return;
        }
        let linkHref = link.getAttribute('href');
        if (linkHref.startsWith(this._host)) {
          let linkText = link.innerHTML;

          // Removes the anchor's hyperlinking behaviour and replaces its text
          link.removeAttribute('href');
          link.innerHTML = this._downloadingPlaceholder;
          if (this._downloadingClassLink) {
            link.classList.add(this._downloadingClassLink);
          }
          try {
            await this.errors.catchActionErrors(this.blobDownload.downloadFile(linkHref), {
              messagePrefix: this._downloadErrorMessagePrefix,
              rethrow: true
            });
          } catch (e) {
            // ignore
          }

          // Restores the anchor's hyperlinking behaviour and the link's text
          link.setAttribute('href', linkHref);
          link.innerHTML = linkText;
          if (this._downloadingClassLink) {
            link.classList.remove(this._downloadingClassLink);
          }
          e.preventDefault();
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "blobDownload", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errors", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});