define("ember-overridable-computed/index", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computed = computed;
  function computed() {
    const OVERRIDE_MAP = new WeakMap();
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    const argumentCopy = [...args];
    const fn = argumentCopy.pop();
    return (0, _object.computed)(...argumentCopy, {
      get() {
        if (OVERRIDE_MAP.has(this)) {
          return OVERRIDE_MAP.get(this);
        }
        for (var _len2 = arguments.length, computedArgs = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          computedArgs[_key2] = arguments[_key2];
        }
        return fn.apply(this, computedArgs);
      },
      set(key, value) {
        OVERRIDE_MAP.set(this, value);
        return value;
      }
    });
  }
});