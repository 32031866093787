define("ember-intl/-private/formatters/format-relative", ["exports", "@ember/debug", "ember-intl/-private/formatters/-base"], function (_exports, _debug, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
  /**
   * An object with some or all of properties of `options` parameter
   * of `Intl.RelativeTimeFormat` constructor.
   *
   * [MDN](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/RelativeTimeFormat#Parameters).
   *
   * [Specification](https://tc39.es/ecma402/#sec-InitializeRelativeTimeFormat).
   */

  /**
   * @private
   * @hide
   */
  class FormatRelative extends _base.default {
    format(intl, value, formatOptions) {
      (false && !(formatOptions) && (0, _debug.assert)(`[ember-intl] FormatRelative: Missing option`, formatOptions));
      const {
        format
      } = formatOptions;
      let unit = formatOptions.unit;
      let opts = formatOptions;
      if (!unit && format && intl.formats.relative && (opts = intl.formats.relative[format])) {
        unit = opts.unit;
      }
      (false && !(unit) && (0, _debug.assert)(`[ember-intl] FormatRelative: 'formatOptions' are missing a 'unit'. ${JSON.stringify(formatOptions)}`, unit));
      return intl.formatRelativeTime(value, unit, opts);
    }
  }
  _exports.default = FormatRelative;
  _defineProperty(FormatRelative, "type", 'relative');
});