define("ember-svg-jar/inlined/merge_cells", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 4v5.001L2 9V4a2 2 0 012-2h7v2H4zM20 4v5.001L22 9V4a2 2 0 00-2-2h-7v2h7zM4 20v-5H2v5a2 2 0 002 2h7v-2H4zM20 20v-5h2v5a2 2 0 01-2 2h-7v-2h7zM6.879 8.464l2.828 2.829c.029.028.055.058.08.09l-.08-.09a1.008 1.008 0 01.292.674L10 12v.033a.997.997 0 01-.293.722L6.88 15.584a1 1 0 01-1.415-1.414L6.634 13H3a1 1 0 010-2h3.585l-1.12-1.121a1 1 0 011.414-1.415zM17.121 8.464l-2.828 2.829a1.006 1.006 0 00-.08.09l.08-.09a1.008 1.008 0 00-.292.674L14 12v.033a.997.997 0 00.293.722l2.828 2.829a1 1 0 001.415-1.414L17.366 13H21a1 1 0 000-2h-3.585l1.12-1.121a1 1 0 10-1.414-1.415z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});