define("@ukgepic/hrsd-ember-ignite/components/ignite-paginated-data-table/-header/-cell", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @sort}}
    {{! template-lint-disable no-invalid-interactive }}
    <ukg-th
      aria-sort={{this.ariaSort}}
      initialSortDirection={{this.ukgSortDirection}}
      sortable="true"
      ...attributes
      {{on "click" this.sortAction}}
      {{on "ukgDataTableSortChanged" this.onUkgDataTableSortChanged}}
    >
      {{yield}}
    </ukg-th>
    {{! template-lint-enable no-invalid-interactive }}
  {{else}}
    <ukg-th ...attributes>
      {{yield}}
    </ukg-th>
  {{/if}}
  */
  {
    "id": "lj+vX9UH",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"ukg-th\"],[16,\"aria-sort\",[30,0,[\"ariaSort\"]]],[16,\"initialSortDirection\",[30,0,[\"ukgSortDirection\"]]],[24,\"sortable\",\"true\"],[17,2],[4,[38,1],[\"click\",[30,0,[\"sortAction\"]]],null],[4,[38,1],[\"ukgDataTableSortChanged\",[30,0,[\"onUkgDataTableSortChanged\"]]],null],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"ukg-th\"],[17,2],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@sort\",\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "@ukgepic/hrsd-ember-ignite/components/ignite-paginated-data-table/-header/-cell.hbs",
    "isStrictMode": false
  });
  let IgnitePaginatedDataTableHeaderCellComponent = _exports.default = (_class = class IgnitePaginatedDataTableHeaderCellComponent extends _component2.default {
    get isSortedAsc() {
      return this.args.currentSort === `+${this.args.sort}`;
    }
    get isSortedDesc() {
      return this.args.currentSort === `-${this.args.sort}`;
    }
    get isSorted() {
      return this.isSortedAsc || this.isSortedDesc;
    }
    get ariaSort() {
      if (!this.isSorted) {
        return 'none';
      }
      return this.isSortedAsc ? 'ascending' : 'descending';
    }
    get ukgSortDirection() {
      if (!this.isSorted) {
        return 'none';
      }
      return this.isSortedAsc ? 'asc' : 'desc';
    }
    onUkgDataTableSortChanged(event) {
      let element = event.target;
      element.setSortDirection(this.ukgSortDirection);
    }
    sortAction() {
      this.args.onSortChange(`${this.isSortedAsc ? '-' : '+'}${this.args.sort}`);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onUkgDataTableSortChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUkgDataTableSortChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sortAction"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IgnitePaginatedDataTableHeaderCellComponent);
});