define("ember-intl/helpers/-format-base", ["exports", "@ember/component/helper", "@ember/application", "@ember/utils"], function (_exports, _helper, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
  /**
   * @private
   * @hide
   */
  class AbstractHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "intl", null);
      _defineProperty(this, "unsubscribeLocaleChanged", null);
      if (this.constructor === AbstractHelper) {
        throw new Error('FormatHelper is an abstract class, can not be instantiated directly.');
      }
      this.intl = (0, _application.getOwner)(this).lookup('service:intl');
      this.unsubscribeLocaleChanged = this.intl.onLocaleChanged(this.recompute, this);
    }
    format() {
      throw new Error('not implemented');
    }
    compute(_ref, options) {
      let [value] = _ref;
      if ((0, _utils.isEmpty)(value)) {
        if (options.allowEmpty ?? this.allowEmpty) {
          return;
        }
        if (typeof value === 'undefined') {
          throw new Error(`${this} helper requires value attribute.`);
        }
      }
      return this.format(value, options);
    }
    willDestroy() {
      super.willDestroy();
      this.unsubscribeLocaleChanged();
    }
  }
  _exports.default = AbstractHelper;
});